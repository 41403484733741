import * as Klaro from './klaro.cjs';
import onLoadAsync from './on_load_async.js';

const SERVICES_UNDER_GTM = [
  'bing-ads',
  'facebook-ads',
  'linkedin',
  'google-ads',
  'ga4',
];

function cookieDomain() {
  const hn = document.location.hostname;
  return hn.slice(hn.indexOf('.'));
}

function getCookiesMatchersListFor(list) {
  return list.map((cookieName) => [cookieName, '/', cookieDomain()]);
}

const defaultConfig = {
  htmlTexts: true,
  default: false,
  mustConsent: false,
  acceptAll: true,
  hideDeclineAll: false,
  noticeAsModal: true,
  disablePoweredBy: true,
  translations: {
    fr: {
      acceptSelected: 'J\'enregistre',
      acceptAll: 'J\'accepte tout',
      privacyPolicyUrl: '/notre-politique-de-cookies',
      consentNotice: {
        description: '<h2 class="title">Pour vos données, c\'est vous qui décidez !</h2>'
        + '<strong>Vous pouvez à tout moment accepter ou refuser nos cookies. Après avoir donné votre consentement, vous pouvez le retirer en cliquant sur le lien <em>Gestion des cookies</em> tout en bas à droite de la page</strong><br /><br />'
        + 'Afin de vous offrir la meilleure expérience possible, nous utilisons des cookies pour assurer une performance optimale du site, récolter des statistiques afin d\'analyser le trafic, permettre un ciblage publicitaire plus fin et mesurer l’attractivité de nos campagnes.<br /><br />'
        + 'Certains cookies sont néanmoins indispensables pour faire fonctionner nos services correctement et assurer une navigation en toute sécurité.',
        learnMore: 'Je choisis',
      },
      consentModal: {
        description: 'Voici la liste des cookies qui sont susceptibles d’être déposés sur votre ordinateur.',
      },
      ok: "J'accepte",
      service: {
        disableAll: {
          description: 'Utilisez ce commutateur pour activer ou désactiver tous les services non obligatoires.',
        },
      },
      purposes: {
        required: {
          title: 'Obligatoire',
          description: 'Ces cookies sont nécessaires pour faire fonctionner nos services correctement et vous ne pouvez pas les désactiver.',
        },
        statistics: {
          title: 'Statistique',
          description: 'Ces outils nous permettent de recueillir des statistiques de fréquentation du site pour comprendre les usages, détecter d\'éventuels problèmes et optimiser l\'ergonomie de notre site.',
        },
        advertising: {
          title: 'Ciblage publicitaire',
          description: 'Afin de faire connaître nos activités, nous faisons la promotion de nos services sur les moteurs de recherche et les réseaux sociaux. Pour cela, nous utilisons les technologies mises à disposition par certains acteurs publicitaires.',
        },
        // livechat: {
        //   title: 'Chat',
        //   description: 'Ces cookies permettent le fonctionnement du chat en ligne',
        // },
      },
    },
  },
  services: [
    {
      name: 'has_js',
      title: 'has_js',
      description: 'Ce cookie permet de vérifier si vous avez JavaScript',
      // cookies: ['has_js'], Klaro can't remove httponly cookies anyways
      default: true,
      purposes: ['required'],
      required: true,
    },
    {
      name: '_session_id',
      title: '_session_id',
      description: 'Ce cookie vous permet de ne pas perdre la souscription commencée en cas de suspension ou déconnexion',
      // cookies: ['_session_id'], Klaro can't remove httponly cookies anyways
      default: true,
      purposes: ['required'],
      required: true,
    },
    {
      name: 'Klaro',
      title: 'Klaro',
      description: 'Ce cookie permet d’enregistrer l’acceptation des cookies',
      cookies: getCookiesMatchersListFor(['klaro']),
      default: true,
      purposes: ['required'],
      required: true,
    },
    {
      name: 'Zendesk',
      title: 'Zendesk',
      description: 'Ce cookie concerne l’utilisation du chat permettant de discuter en direct avec les équipes Enercoop',
      cookies: getCookiesMatchersListFor(['__zlcmid']),
      default: true,
      purposes: ['required'],
      required: true,
    },
    {
      name: 'matomo',
      title: 'Matomo',
      description: 'Ce cookie permet de générer des données statistiques sur '
        + 'la façon dont l’utilisateur utilise notre site.',
      cookies: getCookiesMatchersListFor([/^_pk_.*$/, /^_mtm_.*$/, /^MATOMO.*$/]),
      default: false,
      purposes: ['statistics'],
    },
    {
      name: 'google-tag-manager',
      title: 'GoogleTagManager',
      description: 'Cette interface est indispensable à la transmission de vos données de navigation vers les services listés plus bas',
      default: true,
      purposes: ['advertising'],
      onAccept: `
            // we notify the tag manager about all services that were accepted. You can define
            // a custom event in GTM to load the service if consent was given.
            for(let k of Object.keys(opts.consents)) {
                const consentGiven = opts.consents[k] ? 'accepted' : 'rejected'
                let eventName = ['klaro', k, consentGiven].join('-')
                console.log(eventName)
                dataLayer.push({'event': eventName})

                // check if we use type of consents
                // (https://developers.google.com/tag-platform/tag-manager/templates/consent-apis?hl=fr#consent_state_and_consent_types)
                // const permission = opts.consents[k] ? 'granted' : 'denied'
                // gtag('consent', 'update', { k + '_storage': permission })
            }
        `,
      onInit: `
            // initialization code here (will be executed only once per page-load)
            window.dataLayer = window.dataLayer || [];
            window.gtag = function(){dataLayer.push(arguments)}
            // gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied'})
            // https://developers.google.com/tag-platform/security/guides/consent?hl=fr#redact_ads_data
            // gtag('set', 'ads_data_redaction', true)
        `,
      onDecline: `
            // initialization code here (will be executed only once per page-load)
            window.dataLayer = window.dataLayer || [];
            for(let k of Object.keys(opts.consents)) {
                const consentGivenPart = opts.consents[k] ? 'accepted' : 'rejected'
                let eventName = ['klaro', k, consentGivenPart].join('-')
                console.log(eventName)
                dataLayer.push({'event': eventName})

                // check if we use type of consents
                // (https://developers.google.com/tag-platform/tag-manager/templates/consent-apis?hl=fr#consent_state_and_consent_types)
                // const permission = opts.consents[k] ? 'granted' : 'denied'
                // gtag('consent', 'update', { k + '_storage': permission })
            }
        `,
      vars: {
        bingAds: 'bing-ads',
        facebookAds: 'facebook-ads',
        googleAds: 'google-ads',
        linkedIn: 'linkedin',
        ga4: 'ga4',
      },
    },
    {
      // In GTM, you should define a custom event trigger named `klaro-bing-ads-accepted`
      // which should trigger the BingAds integration.
      name: 'bing-ads',
      purposes: ['advertising'],
      cookies: getCookiesMatchersListFor(['MUID', 'MUIDB', /^_uet.*/]),
      description: 'Cookie déposé par Microsoft pour suivre les recherches Bing.',
      default: false,
    },
    {
      // In GTM, you should define a custom event trigger named `klaro-facebook-ads-accepted`
      // which should trigger the FacebookAds integration.
      name: 'facebook-ads',
      purposes: ['advertising'],
      cookies: getCookiesMatchersListFor(['_fbp']),
      description: 'Cookie déposé par Facebook à des fins publicitaires.',
      default: false,
    },
    {
      // In GTM, you should define a custom event trigger named `klaro-google-ads-accepted`
      // which should trigger the GoogleAds integration.
      name: 'google-ads',
      purposes: ['advertising'],
      cookies: getCookiesMatchersListFor([/^_gcl_.*/]),
      description: 'Cookie déposé par Google à des fins publicitaires.',
      default: false,
    },
    {
      // In GTM, you should define a custom event trigger named `klaro-linkedin-accepted`
      // which should trigger the LinkedIn integration.
      name: 'linkedin',
      purposes: ['advertising'],
      cookies: getCookiesMatchersListFor(['ln_or']),
      description: 'Cookies déposé par LinkedIn',
      default: false,
    },
    {
      name: 'youtube',
      title: 'YouTube',
      description: 'Ce cookie permet à YouTube de personnaliser votre '
        + 'expérience de visionnage.',
      cookies: getCookiesMatchersListFor([/^VISITOR_INFO1_LIVE$/, /^YSC$/]),
      default: false,
      purposes: ['advertising'],
    },
  ],
};

function lastGTMServiceIs(service) {
  return !SERVICES_UNDER_GTM.filter((item) => item !== service).some(
    (serviceName) => Klaro.getManager().getConsent(serviceName),
  );
}

function klaroConfig() {
  return {
    ...defaultConfig,
    testing: false,
    cookieDomain: cookieDomain(),
  };
}

onLoadAsync(() => {
  if (window.initKlaro) {
    window.initKlaro(Klaro, klaroConfig);
    const klaroManager = Klaro.getManager();

    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.addedNodes[0] && mutation.addedNodes[0].className === 'cookie-modal') {
          SERVICES_UNDER_GTM.forEach((gtmService) => {
            document.getElementById(`service-item-${gtmService}`)?.addEventListener('change', (event) => {
              if (event.target.checked) {
                // enable gtm if one of its subservices is allowed
                klaroManager.updateConsent('google-tag-manager', true);
              } else if (lastGTMServiceIs(gtmService)) {
                // disable gtm if none of its subservices is allowed
                klaroManager.updateConsent('google-tag-manager', false);
              }
            });
          });

          // disable all services if gtm is disabled
          const GTM_CHECKBOX = document.getElementById('service-item-google-tag-manager');
          GTM_CHECKBOX.addEventListener('change', (event) => {
            if (event.target.checked === false) {
              SERVICES_UNDER_GTM.forEach((gtmService) => {
                klaroManager.updateConsent(gtmService, false);
              });
            }
          });
        }
      });
    });
    observer.observe(
      document.querySelector('#klaro .klaro'),
      { attributes: false, childList: true, subtree: false },
    );
  }
});
